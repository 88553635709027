import PbContentMedia from "@/components/content/pbContentMedia/pbContentMedia";
import useScopedClassName from "@/hooks/useScopedClassName";
import { ContentElementMultimediaStoreSetting } from "@/types/ceSettings/ceSettings";
import { CEMultimedia } from "@/types/content-elements";
import {
  MEDIA_TYPE_AUDIO,
  MEDIA_TYPE_VIDEO,
  MEDIA_TYPE_YOUTUBE,
} from "@/utils/constants";
import { getPbContentScopedSelector } from "@/utils/util";
import clsx from "clsx";
import React from "react";
import { Col, Row } from "react-bootstrap";
import ContentElementWrapper from "../wrapper/contentElementWrapper";
import MultimediaContentElementStyleGlobal from "./multimediaContentElementStyleGlobal";
import MultimediaContentElementStyleScoped from "./multimediaContentElementStyleScoped";

export interface MultimediaContentElementProps {
  content: CEMultimedia;
  position: number;
  ceSettings?: ContentElementMultimediaStoreSetting;
  isLastElement: boolean;
  isMobile: boolean;
  isFirstElement: boolean;
}

export const MULTIMEDIA_CE_NAME = "multimediaCE";

export default React.memo(function MultimediaContentElement(
  props: MultimediaContentElementProps
) {
  const MULTIMEDIA_SCOPED_CLASS = useScopedClassName(MULTIMEDIA_CE_NAME, props.content, props.position);

  return (
    <>
      <ContentElementWrapper
        scopedClassName={MULTIMEDIA_SCOPED_CLASS}
        name={MULTIMEDIA_CE_NAME}
        content={props.content}
        ceSettings={props.ceSettings}
        isFirstElement={props.isFirstElement}
        isLastElement={props.isLastElement}
        position={props.position}
      >
        <Row
          className={clsx(
            MULTIMEDIA_CE_NAME,
            MULTIMEDIA_SCOPED_CLASS,
            "g-0 justify-content-center"
          )}
        >
          <Col xs="12" md="10" xl="6">
            <PbContentMedia
              scopedSelector={getPbContentScopedSelector(
                MULTIMEDIA_SCOPED_CLASS
              )}
              showButtonOverlayOnlyOnInit
              cmsPosition={props.position}
              mediaOptions={[
                MEDIA_TYPE_AUDIO,
                MEDIA_TYPE_VIDEO,
                MEDIA_TYPE_YOUTUBE,
              ]}
              youtubeContent={{
                url: props.content?.youtubeUrl || "",
                cmsFieldYoutubeUrl: "youtubeUrl",
              }}
              videoContent={{
                video: props.content.video,
                videoThumbnail: props.content.videoTn,
                cmsFieldVideo: "video",
                cmsFieldVideoThumbnail: "videoTn",
              }}
              audioContent={{
                audio: props.content.audio,
                cmsFieldAudio: "audio",
              }}
            />
          </Col>
        </Row>
      </ContentElementWrapper>
      <MultimediaContentElementStyleGlobal {...props} />
      <MultimediaContentElementStyleScoped
        {...props}
        scopedSelector={MULTIMEDIA_SCOPED_CLASS}
      />
    </>
  );
});
