import {
  MULTIMEDIA_CE_NAME,
  MultimediaContentElementProps,
} from "./multimediaContentElement";

/**
 * This is the global style of a CE.
 * This style is applied on all instances of this CE
 *
 * @param props
 * @returns
 */
export default function MultimediaContentElementStyleGlobal(
  props: MultimediaContentElementProps
) {
  return (
    <style jsx global>
      {`
        :global(.${MULTIMEDIA_CE_NAME}) {
        }
      `}
    </style>
  );
}
