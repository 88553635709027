import {
  lowerBreakpointDesktopPx,
  lowerBreakpointTabletPx,
  lowerBreakpointWqhdPx,
} from "@/utils/util";
import ViewportPreviewStyles from "../wrapper/viewportPreviewWrapper";
import { MultimediaContentElementProps } from "./multimediaContentElement";

interface MultimediaContentElementStyleScopedProps
  extends MultimediaContentElementProps {
  scopedSelector: string;
}

/**
 * This is the scoped style from a single CE instance
 * Here you can explicitly style one CE
 */
export default function MultimediaContentElementStyleScoped(
  props: MultimediaContentElementStyleScopedProps
) {
  return (
    <>
      <style jsx global>{`
        :global(.${props.scopedSelector}) {
          .cms-content-media-youtube-container,
          .cms-content-media-video,
          .preview-image,
          iframe {
            width: ${props.ceSettings?.mediaMaxWidth?.mobile || "300"}px;
            height: 100%;
            aspect-ratio: 16 / 9;
            max-width: 100%;

            @media screen and (min-width: ${lowerBreakpointTabletPx()}) {
              width: ${props.ceSettings?.mediaMaxWidth?.tablet || "400"}px;
            }
            @media screen and (min-width: ${lowerBreakpointDesktopPx()}) {
              width: ${props.ceSettings?.mediaMaxWidth?.desktop || "600"}px;
            }
            @media screen and (min-width: ${lowerBreakpointWqhdPx()}) {
              width: ${props.ceSettings?.mediaMaxWidth?.wqhd || "600"}px;
            }
          }
        }
      `}</style>
      <ViewportPreviewStyles>
        <style>{`
          .${props.scopedSelector} .cms-content-media-youtube-container,
          .${props.scopedSelector} .cms-content-media-video,
          .${props.scopedSelector} .preview-image,
          .${props.scopedSelector} iframe {
            width: ${props.ceSettings?.mediaMaxWidth?.mobile || "300"}px !important;
          }

          @container content-wrapper (min-width: ${lowerBreakpointTabletPx()}) {
            .${props.scopedSelector} .cms-content-media-youtube-container,
            .${props.scopedSelector} .cms-content-media-video,
            .${props.scopedSelector} .preview-image,
            .${props.scopedSelector} iframe {
              width: ${props.ceSettings?.mediaMaxWidth?.tablet || "400"}px !important; 
            }
          }
          @container content-wrapper (min-width: ${lowerBreakpointDesktopPx()}) {
            .${props.scopedSelector} .cms-content-media-youtube-container,
            .${props.scopedSelector} .cms-content-media-video,
            .${props.scopedSelector} .preview-image,
            .${props.scopedSelector} iframe {
              width: ${props.ceSettings?.mediaMaxWidth?.desktop || "600"}px !important;
            }
          }
          @container content-wrapper (min-width: ${lowerBreakpointWqhdPx()}) {
            .${props.scopedSelector} .cms-content-media-youtube-container,
            .${props.scopedSelector} .cms-content-media-video,
            .${props.scopedSelector} .preview-image,
            .${props.scopedSelector} iframe {
              width: ${props.ceSettings?.mediaMaxWidth?.wqhd || "600"}px !important;
            }
          }

        `}</style>
      </ViewportPreviewStyles>
    </>
  );
}
